.issues {
  max-width: 1600px;

  .title {
    margin-bottom: 24px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;

    button {
      margin-left: 10px;
    }
  }

  .kanbanBoard {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .date {
      width: 100%;
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      align-items: center;

      .range {
        font-weight: bold;
      }
    }

    .weeks {
      width: 100%;
      display: flex;

      .week {
        width: 25%;
        display: flex;
        padding-right: 12px;
        flex-direction: column;

        .weekday {
          display: flex;
          justify-content: center;
          padding: 20px;
          font-weight: bold;

        }
      }
    }
  }
}

.blind {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .3);
  position: fixed;
  z-index: 9999;
}
