.notificationItem {
  display: flex;
  margin-bottom: 20px;
  padding: 15px;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  position: relative;

  .information {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    .title {
      display: flex;
    }

    .repository {
      margin: 0;
      margin-right: 20px;
    }

    .date {
      display: flex;
      font-size: 12px;
      margin: 0;
      color: lighten(#868e96, 10%);
    }
  }

  .branch {
    font-size: 12px;
    display: inline-block;
    padding: 0 5px;
    margin: 0;
    margin-right: 10px;
    color: #868e96;
    min-width: 55px;
    box-sizing: border-box;
    text-align: center;
    background: lighten(#868e96, 30%);

    &.isMaster {
      color: #fa5252;
      background: lighten(#fa5252, 30%);
    }

    &.isDevelop {
      color: #228be6;
      background: lighten(#228be6, 40%);
    }
  }

  .size {
    margin-right: 5px;
  }

  .diff {
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #e67e22;

    svg {
      font-size: 16px;
      display: flex;
      margin-right: 5px;
    }

    &.isDown {
      color: #40c057;
    }

    &.isUp {
      color: #fa5252;
    }
  }

  .additional {
    display: flex;
    align-items: center;
  }

  .file {
    display: flex;
  }

  .message {
    margin: 0;
  }

  .commit {
    margin: 0;
    color: #868e96;
    font-size: 11px;
  }
}
