.features {
  max-width: 600px;

  .empty {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    padding: 150px 0;
    color: #868e96;
  }

  .title {
    margin-bottom: 24px;
  }
}
