.featureItem {
  display: flex;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  position: relative;
  transition: opacity 0.15s cubic-bezier(0.4, 0.0, 0.2, 1);

  .actions {
    display: flex;
    padding-top: 15px;
    align-items: flex-end;
    justify-content: space-between;

    a {
      color: #1a54f9;
    }

    .delete {
    }
  }

  &.isDisabled {
    opacity: .5;
  }

  .date {
    display: inline-block;
    padding: 0 5px;
    font-size: 12px;
    position: absolute;
    top: 20px;
    right: 20px;
    color: lighten(#868e96, 10%);
  }
}
