.dayItem {
  width: 10px;
  height: 10px;
  background: #fafafa;
  transition: background 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;

  &.isAfterToday {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    .tooltip {
      display: flex;
      flex-direction: column;
    }
  }

  .tooltip {
    display: none;
    position: absolute;
    background: #343a40;
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
    padding: 5px 7.5px;
    transform: translate(-50%, -100%);
    left: 100%;
    top: -6px;
    pointer-events: none;
    z-index: 9999;

    .date {
      text-align: center;
      opacity: 0.5;
    }

    .count {
      opacity: 0.8;
      margin-top: 3px;
    }

    &::after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #343a40;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transform: translate(-50%, 100%);
    }
  }
}
