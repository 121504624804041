.issueItem {
  display: flex;
  margin-bottom: 12px;
  flex-direction: column;
  position: relative;

  &.isCompleted {
    opacity: .6;
  }

  .content {
    display: flex;
    flex-direction: column;

    .key {
      font-size: 12px;
      font-weight: normal;
      margin-bottom: 6px;
      color: #868e96;
    }

    .summary {
      font-size: 14px;
      line-height: 1.5;
      word-break: break-all;
    }

    .description {
      display: flex;
      white-space: pre-wrap;
      font-size: 14px;
      margin-top: 12px;
      line-height: 1.5;
      word-break: break-all;
    }
  }

  .tags {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;

    & > span {
      color: #fff;
      font-size: 11px;
      padding: 4px 6px;
      border-radius: 4px;
    }

    .inReview {
      background: #2c3e50;
    }

    .completed {
      background: #40c057;
    }
  }
}
