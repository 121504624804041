.issueForm {
  position: fixed;
  width: 500px;
  height: 372px;
  background: #ffffff;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;

  .inner {
    padding: 40px;
    transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);

    &.isLoading {
      opacity: 0.5;
    }

    .title {
      margin-bottom: 20px;
    }

    .summary,
    .description {
      margin-bottom: 10px !important;
    }

    .actions {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 10px;
      }
    }
  }
}
