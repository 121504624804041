.textInput {
  display: flex;

  .input {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    min-width: 300px;
    overflow: hidden;
    transition: border-color 0.15s cubic-bezier(0.4, 0.0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0.0, 0.2, 1);
    box-shadow: inset 0 0 0 1px #dee2e6;
    border-radius: 4px;

    .placeholder {
      font-size: 14px;
      position: absolute;
      top: 14px;
      margin: auto;
      left: 16px;
      height: 14px;
      pointer-events: none;
      color: #868e96;
      transition: opacity 0.15s cubic-bezier(0.4, 0.0, 0.2, 1), transform 0.15s cubic-bezier(0.4, 0.0, 0.2, 1);

      &:global(.enter) {
        opacity: 0;
        transform: translate(0px, 2px);
      }

      &:global(.enter-active) {
        opacity: 1;
        transform: translate(0px, 0px);
      }

      &:global(.exit) {
        opacity: 1;
        transform: translate(0px, 0px);
      }

      &:global(.exit-active) {
        opacity: 0;
        transform: translate(0px, 2px);
      }
    }

    textarea {
      font-size: 14px;
      border: none;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 12px 16px;
      background: none;
      resize: none;
      min-width:100%;
      min-height: 150px;
    }
  }

  &.isFocus {
    .input {
      box-shadow: inset 0 0 0 3px #1a54f9;
    }
  }
}
