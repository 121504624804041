.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  background: #ffffff;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  box-shadow: 0 4px 16px 2px rgba(0, 0, 0, .1);
  z-index: 999;

  .inner {
    display: flex;
    flex-direction: column;
  }
}
