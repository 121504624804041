.notifications {
  display: flex;
  flex-direction: column;

  .list {
    max-width: 600px;
  }

  .title {
    margin-bottom: 24px;
  }
}
