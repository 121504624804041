.button {
  -webkit-appearance: none;
  appearance: none;
  font-size: 14px;
  box-sizing: border-box;
  padding: 10px 14px;
  border: none;
  cursor: pointer;
  position: relative;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.4, 0.0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);

  &.primary {
    color: #fff;
    background: #1a54f9;

    &:hover {
      background: darken(#1a54f9, 10%);
    }

    &:active {
      background: darken(#1a54f9, 15%);
    }
  }

  &.outline {
    background: #fff;
    color: #1a54f9;
  }

  &.danger {
    background: #ff4d4d;
    color: #fff;

    &:hover {
      background: darken(#ff4d4d, 10%);
    }

    &:active {
      background: darken(#ff4d4d, 15%);
    }
  }
}
