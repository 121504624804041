.dashboard {

  .title {
    margin-bottom: 24px;
  }

  .groups {
    display: flex;

    .group {
      display: flex;
      flex-direction: column;
    }
  }
}
