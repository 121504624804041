.layout {
  display: flex;

  .contentWrapper {
    padding-left: 250px;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    .contentInner {
      padding: 40px;
      position:relative;
      box-sizing: border-box;
      width: 100%;
    }
  }
}
