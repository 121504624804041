.typography {
  color: #4b4b4b;

  &.h1 {
    font-size: 32px;
    margin-bottom: 14px;
  }

  &.h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  &.h3 {
    font-size: 18px;
    margin-bottom: 6px;
  }

  &.p {
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 6px;
  }
}
