.header {
  width: calc(100% - 250px);
  height: 60px;
  top: 0;
  left: 250px;
  position: fixed;
  box-shadow: 0 4px 16px 2px rgba(0, 0, 0, .1);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background: #ffffff;
  z-index: 99;
  justify-content: flex-end;

  .logout {
    margin-right: 10px;
  }
}
