.sidebarItem {
  padding: 12px 16px;
  cursor: pointer;
  transition: background 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  font-size: 12px;
  text-decoration: none;
  color: #343a40;
  position: relative;

  &:hover {
    background: lighten(#1a54f9, 45%);

  }

  &:active {
    background: lighten(#1a54f9, 40%);

  }

  .typography {
    margin: 0;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.isActivated {
    background: #1a54f9;

    .typography {
      color: #fff;
    }
  }
}
